import React from "react";
import { PageProps } from "gatsby";
import styled from "styled-components";
import MoonLoader from "react-spinners/MoonLoader";

import { PageLayout } from "src/components/layout/PageLayout";
import { PageContent } from "src/components/layout/PageContent";
import { breakpoint } from "src/utils/media-query";
import { useWindowSize } from "src/hooks/useWindowSize";

interface BookingContext {
  title: string;
  booking_url: string;
}

const BookPage: React.FC<PageProps<Record<string, unknown>, BookingContext>> = ({ pageContext }) => {
  const { height } = useWindowSize();
  const [ loading, setLoading ] = React.useState(true);

  return (
    <Wrapper>
      <PageLayout title={pageContext.title} width="full">
        {loading && (
          <LoadingWrapper>
            <MoonLoader size={40} color="#C81E17" />
          </LoadingWrapper>
        )}
        <BookingIFrame
          src={pageContext.booking_url}
          onLoad={() => setLoading(false)}
          title="Event page"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          $height={height}
        />
      </PageLayout>
    </Wrapper>
  );
};

export default BookPage;

const Wrapper = styled.div`
  ${PageContent} {
    padding-bottom: 0.1rem;
  }
`;

const LoadingWrapper = styled.div`
  margin-top: 15rem;
  padding: 1rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.offWhite}aa;
  border-radius: 5px;
`;

const BookingIFrame = styled.iframe<{ $height: number }>`
  width: 100%;
  flex: 1;

  flex-basis: ${({ $height }) => $height - 63}px;

  ${breakpoint("mobileLP")} {
    flex-basis: ${({ $height }) => $height - 75}px;
  }

  ${breakpoint("mobileSL")} {
    flex-basis: ${({ $height }) => $height - 100}px;
  }

  ${breakpoint("tabletLL")} {
    flex-basis: ${({ $height }) => $height - 177.5}px;
  }
`;